header {
  height: 100%;
  position: relative;
  z-index: 999;
  width: 0;
  padding: 0;
  max-width: 450px;
  background-color: #FFF;
}
header.active {
  opacity: 1;
  width: 475px;
}
.openMenu button img {
  width: 50px;
  height: 57.75px;
}
.openMenu button img:hover {
cursor: pointer;
}

#menuButton {
  background: transparent;
  border: none;
  width: 53px;
    height: 61px;
    padding: 0;
}
#menuButton svg {
  width: 53px;
  height: 59.738px;
}
footer {
  z-index: 9999;
  mix-blend-mode: difference;
}
ul {
  list-style: none;
  padding-left:0;
}
.copy {
  height: 0;
  width: calc(100% + 1px);
  display: flex;
  border-top: 1px solid;
  background: white;
    z-index: 999;
}
#masthead li {
  opacity: 0;
}
#masthead ul li.large {
padding-bottom: 15px;
}
#masthead ul li.small {
  padding-left: 48px;
 padding-bottom: 15px;
 padding-top: 15px;
}
.mainMenu {
  padding: 0;
  margin: 0;
}
#masthead ul li.small a {
  transition: all ease 1s;
  position: relative;
}
#masthead ul li.large a {
  transition: all ease 1s;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #000;
  -webkit-text-stroke-width: 1px;
}
#masthead ul li.large a:hover {
  color: #000;
    -webkit-text-fill-color: #000;
    -webkit-text-stroke-color: #000;
    -webkit-text-stroke-width: 0;
}
#masthead ul li.large a.active {
  color: #000;
    -webkit-text-fill-color: #000;
    -webkit-text-stroke-color: #000;
    -webkit-text-stroke-width: 0;
}
#masthead ul li.small a:after {
  width: 0;
  border-bottom: 1px solid #000;
  content: " ";
  position: absolute;
  left: 0;
  bottom: -1px;
  transition: all ease 0.5s;
}
#masthead ul li.small a:hover:after {
width: 100%;
}
#masthead ul li.small a.active:after {
width: 100%;
}
p#invert {
  display: block;
  mix-blend-mode: difference;
}
p#invert span {
  display: block;
  margin-right: 0;
  color: #fff;
}
p#invert span:first-of-type {
  display: block;
  margin-bottom: 0;
  background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 2px 0 3px 0;
    text-decoration: none;
    border: none;
}
p#invert span:nth-child(2) {
  display: block;
  margin-bottom: 15px;
}
p#invert span span {
  display: inline-flex;
    position: absolute;
    opacity: 0;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
p#invert span span:before {
display: none;
}
p#invert span span:after {
display: none;
}
p#invert span.light {
  background: #000;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
}
p#invert span.dark {
  background: #FFF;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-bottom: 0;
}
.dark p#invert span.dark {
  background: #000;
    border: 1px solid #fff;
}
.dark p#invert span.light {
  background: #fff;
    border: 1px solid #fff;
}
p#invert span:hover {
  text-decoration: none;
}
#invert {
  position: absolute;
  bottom: 65px;
  right: 70px;
  z-index: 9999;
}
.subMenu {
  
}



@media (max-width: 767px) {
  footer {
    position: absolute;
    bottom: 9px;
    left: 8px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    z-index: 9999;
  }
  #menuButton svg {
    width: 29px;
    height: 31px;
  }
  #menuButton {
    width: 40px;
    height: 40px;
}
#invert {
  bottom: 21px;
  right: 16px;
}
#masthead ul li.desktop {
  display: none;
}
}
@media (min-width: 768px) {
#masthead ul li.mobile {
  display: none;
}
}
.ourCredits {
  padding: 15px;
  margin-bottom: 60px;
}

.copyCopy {
  border-top: 1px solid;
}
.copyCopy {
padding: 15px 30px 15px 15px;
line-height: 1.5;
}
.copyCopy p {
  display: inline-block;
}
.copyCopy span {
}
#copyBlock {
  width: 100%;
  position: relative;
}
.copy a:after {
  width: 0;
  border-bottom: 1px solid #000;
  content: " ";
  position: absolute;
  left: 0;
  bottom: -1px;
  transition: all ease 0.5s;
}
.copy a:hover::after {
  width: 100%;
}
@media (max-width: 767px) {
  #masthead {
    position: absolute;
    max-width: 100%;
    background: #FFF;
    left: 0 !important;
    padding: 0 !important;
    border-right: 1px solid;
  }
  .copyCopy {
    padding-bottom: 60px;
  }
  .ourCredits {
    margin-bottom: 30px;
  }
  #dynamicMenu {
    padding: 15px;
  }
  #featuredProjects #container {
    max-width: 100%;
  }
  #allWorksLink {
    background: #fff;
    mix-blend-mode: difference;
    color: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  #allWorksLink a {
position: initial;
  }
}
#appearance {
  transform: rotate(90deg);
  position: absolute;
    left: -30px;
    top: -60px;
}