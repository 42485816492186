#feattitle {
  color: #FFF;
  max-width: 70vw;
}
.featCont {
  mix-blend-mode: difference;
}
@media (max-width: 767px) {
  #featuredProjects #container {
    width: 100%;
    height: 100%;
  }
  .slideLink {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .slide {
    aspect-ratio: unset;
  }
  .slide img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    aspect-ratio: unset;
  }
  .slideLink.current {
    z-index: 99;
  }
}
.featCont {
  overflow: hidden;
  padding-bottom: 2px;
  flex-wrap: wrap;
}
.featCont h2 {
  position: relative;
  top: -100vh;
}
#featuredProjects {
  overflow: hidden;
  position: relative;
}
#featuredProjects #container {
  left: -100vw;
}
#featartist {
  color: #fff;
  width: 100%;
  margin-top: 15px;
  padding-left: 15px;
  background: #fff;
    mix-blend-mode: difference;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 2px;
    bottom: -25px;
    position: relative;
    padding-bottom: 2px;
}
@media (max-width: 767px) {
  .slide {
    width: calc(100% + 7px);
    height: calc(100% + 8px);
    top: -7px;
    left: -6px;
    transform: translate(0px, 0px);
  }
  .slideLink {
    width: calc(100% + 7px);
    height: calc(100% + 8px);
    top: -7px;
    left: -6px;
    position: absolute;
}
.slide img {
  width: calc(100% + 7px);
    height: calc(100% + 8px);
}
}
#homePage .dots {
  transform: translateY(-50%);
}
#homePage .dots .dot {
  border-bottom: 1px solid transparent;
transition: ease all 1.2s;
}
#homePage .dots .dot.active {
border-bottom: 1px solid rgb(255, 255, 255);
}
@media (max-width: 767px) {
  #homePage .dots {
  right: 4px;
}
}