html {
  --opacity: 0;
}
#worksArchive {
  width: calc(100% + 1px);
  display: block;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  left: -1px;
  padding-bottom: 120px;
}

@media (min-width: 1099px) {
.masonry {  
  display: grid;
  /*grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(3, 20vh);*/
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
}
}
main {
  position: relative;
}
/*
.gridItem:nth-child(18n + 1) { grid-column: 1 / 5;grid-row: span 2;}
.gridItem:nth-child(18n + 2) { grid-column: 5 / 9;grid-row: span 2;}
.gridItem:nth-child(18n + 3) { grid-column: 9 / 15;grid-row: span 4;}
.gridItem:nth-child(18n + 4) { grid-column: 1 / 4;grid-row: span 2;  }
.gridItem:nth-child(18n + 5) { grid-column: 5 / 9;grid-row: span 4; }
.gridItem:nth-child(18n + 6) { grid-column: 15 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 9) { grid-column: 13 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 8) { grid-column: 9 / 12;grid-row: span 2; }
.gridItem:nth-child(18n + 7) { grid-column: 1 / 5;grid-row: span 2;}
.gridItem:nth-child(18n + 10) { grid-column: 3 / 9;grid-row: span 4;}
.gridItem:nth-child(18n + 11) { grid-column: 9 / 13;grid-row: span 2;}
.gridItem:nth-child(18n + 12) { grid-column: 13 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 13) { grid-column: 9 / 13;grid-row: span 4;}
.gridItem:nth-child(18n + 14) { grid-column: 14 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 15) { grid-column: 1 / 3;grid-row: span 1;}
.gridItem:nth-child(18n + 16) { grid-column: 1 / 5;grid-row: span 2;}
.gridItem:nth-child(18n + 17) { grid-column: 6 / 9;grid-row: span 2;}
.gridItem:nth-child(18n + 18) { grid-column: 13 / 17;grid-row: span 2;}
*/ 
@media (min-width: 768px) and (max-width:1098px) {
  .masonry {  
    display: grid;
    /*grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(3, 20vh);*/
    grid-template-columns: repeat(3, 1fr);    gap: 0;
  }
/*
.gridItem:nth-child(18n + 1) { grid-column: 1 / 9;grid-row: span 2;}
.gridItem:nth-child(18n + 2) { grid-column: 9 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 3) { grid-column: 1 / 7;grid-row: span 4;}
.gridItem:nth-child(18n + 4) { grid-column: 8 / 13;grid-row: span 4;  }
.gridItem:nth-child(18n + 5) { grid-column: 13 / 17;grid-row: span 4; }
.gridItem:nth-child(18n + 6) { grid-column: 1 / 13;grid-row: span 4;}
.gridItem:nth-child(18n + 7) { grid-column: 13 / 17 ;grid-row: span 2;}
.gridItem:nth-child(18n + 8) { grid-column: 1 / 9;grid-row: span 2; }
.gridItem:nth-child(18n + 9) { grid-column: 11 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 10) { grid-column: 3 / 9;grid-row: span 2;}
.gridItem:nth-child(18n + 11) { grid-column: 9 / 13;grid-row: span 2;}
.gridItem:nth-child(18n + 12) { grid-column: 13 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 13) { grid-column: 1 / 8;grid-row: span 2;}
.gridItem:nth-child(18n + 14) { grid-column: 8 / 16;grid-row: span 2;}
.gridItem:nth-child(18n + 15) { grid-column: 1 / 9;grid-row: span 2;}
.gridItem:nth-child(18n + 16) { grid-column: 9 / 17;grid-row: span 2;}
.gridItem:nth-child(18n + 17) { grid-column: 1 / 9;grid-row: span 2;}
.gridItem:nth-child(18n + 18) { grid-column: 12 / 17;grid-row: span 2;}
}
*/
}
@media (min-width: 501px) and (max-width:767px) {
  .masonry {  
    display: grid;
    /*grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(3, 20vh);*/
    grid-template-columns: repeat(2, 1fr);    gap: 0;
  }
}
.masonry {
  margin-left: auto;
    max-width: calc(100%);
    background: #FFF;
    position: relative;
    top: -1px;
}
/* fluid 5 columns */
.grid-sizer,
/* 2 columns wide */
.gridItem { border: 1px solid #000 !important;}

.gridItem {
  padding: 15px;
  position: relative;
    /* top: -1px; */
    /* left: -1px; */
    width: calc(100% - 31px);
    height: calc(100% - 31px);
    overflow: hidden;
    background-color:#fff;
    aspect-ratio: 1/1;
}
.masonry:after {
  content: '';
  display: block;
  clear: both;
}
.span1 {
  
}
.span2 {
}
.span3 {
  
}
.gridItem img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  transform: scale(1.25);
  position: relative;
  left: -200%;
}
.gridItem a {
  width: 100%;
  line-height: 0;
}
.gridItem a:hover {
  text-decoration: none;
}
.gridItem a:hover::after {
  display: none;
}
.gridItem .zoomed {
  overflow: hidden;
  background: #FFF;
  height: 100%;
  position: relative;
}
.gridItem .zoomed:after {
  content: '';
  position: absolute;
  background: #000;
  opacity: var(--opacity);
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  left: 0;
}
.gridTitle {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #FFF;
  z-index: 10;
}
.gridTitle p {
  color: #FFF !important;
}
.dark .gridTitle p {
  filter: invert(1);
}
.pageTitle {
  border-bottom: 1px solid;
}
.pageTitle h1 {
  margin: 0;
  width: 100%;
  padding: 15px 0 15px 15px;
}

.fades {
  overflow: hidden;
    padding-top: 2px;
    mix-blend-mode: difference;
    -webkit-background-clip: text;
    color: #fff;
}
@media (max-width: 767px) {
  .grid-sizer, .gridItem {
    margin-top: -1px;
}
}
.gridArtist {
  overflow: hidden;
  padding-top: 2px;
}
.masonry .gridArtist h4 {
  color: #fff;
  bottom: -50px;
    position: relative;
    padding-bottom: 2px;
    padding-top: 2px;
}
.masonry .gridTitle h3 {
  color: #fff;
  top: -50px;
    position: relative;
}