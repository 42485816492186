.gridItemList {
  border-bottom: 1px solid;
  position: relative;
}
.gridItemList a {
display: block;
    height: 100%;
    width: 100%;
    padding-bottom: 60px;
    padding-top: 15px;
}
.masonryList .gridTwoTitle {
padding: 3px 18px;
color: #000;
position: relative;
}
.masonry .gridTitle h3 {
color: #fff;
}
.page-container {
  position: relative;
}
.textMode {
  position: absolute;
    top: 15px;
    right: 15px;
    z-index: 99;
}
.textMode button {
  display: block;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: #000;
  padding-top: 1px;
}

.gridItemList a h3 {
  transition: all ease 3s;
  width: calc(70% - 7.5px);
}
.gridItemList .gridTwoArtist {
  width: calc(30% - 7.5px);
}
.gridTwoTitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .gridItemList a h3 {
    transition: all ease 3s;
    width: 100%;
    padding-top: 2px;
  }
  .gridItemList .gridTwoArtist {
    width: 100%;
    padding-top: 15px;
  }
  .masonryList .gridTwoTitle {
    padding: 0 15px;
    }
}
.gridItemList a:hover h3 {
  transition: all ease 3s;
  max-width: 70vw;
}
.oneone  {
aspect-ratio: 1 / 1;
}
.sixnine {
  aspect-ratio: 16 / 9;

}
.twothree  {
  aspect-ratio: 2 / 3;

}
.threetwo {
  aspect-ratio: 3 / 2;

}
#jsListView .gridView {
position: relative;
}
#jsgridView .ListView {
position: relative;
}
#jsGridView .gridView{
  display: none;
  }
  #jsListView .listView  {
 display: none;
  }
  #jsListView .pageTitle h1 {
    font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0;
  padding-left: 30px;
  transition: all 1.2s cubic-bezier(.44,.15,.2,1)!important;
  }
  #jsGridView .pageTitle h1 {
    transition: all 1.2s cubic-bezier(.44,.15,.2,1)!important;
  }
  #works div#allWorksLink {
    position: relative !important;
    bottom: auto;
      left: auto;
      right: 0;
      margin: 0;
      padding-top: 45px;
  }
  div#allWorksLink a:after {
    width: 0;
  border-bottom: 1px solid #000;
  content: " ";
  position: absolute;
  left: 0;
  bottom: -1px;
  transition: all ease 0.5s;
  }
  div#allWorksLink a:hover::after {
width: 100%;
  }
  #worksProjects .pageTitle a:after {
    width: 0;
    border-bottom: 1px solid #000;
    content: " ";
    position: absolute;
    left: 0;
    bottom: -1px;
    transition: all ease 0.5s;
  }
  #worksProjects .pageTitle a:hover::after {
    width: 100%;
  }
  #works .standardScroll {
    width: calc(100% + 1px);
    max-width: calc(100% + 1px);
  }
  .gridArtist {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #FFF;
    z-index: 10;
  }
 
  .square a {
    display: block;
    width: 100%;
    height: 100%;
  }
  .listImage {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #FFF;
}
.masonryList .gridItemList {
  border-bottom: 1px solid #000;
}
.dark .masonryList .gridItemList {
  position: relative;
}
.masonryList .gridItemList .gridItemListPseudo {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.light .masonryList .gridItemList .gridItemListPseudo {

}
.dark .masonryList .gridItemList .gridItemListPseudo {
  filter: invert(1);
}